@font-face {
  font-family: "Roboto";
  src: url("./fonts/Roboto.woff2") format("woff2"),
    url("./fonts/Roboto.woff") format("woff");
}
@font-face {
  font-family: "RobotoMedium";
  src: url("./fonts/RobotoMedium.woff2") format("woff2"),
    url("./fonts/RobotoMedium.woff") format("woff");
}
@font-face {
  font-family: "RobotoRegular";
  src: url("./fonts/RobotoRegular.woff2") format("woff2"),
    url("./fonts/RobotoRegular.woff") format("woff");
}

body {
  font-family: Roboto;
  margin: 0;
}

.header {
  background-color: white;
  box-shadow: 0 0 8px 0 black;
  box-sizing: border-box;
  display: table;
  height: 50px;
  padding: 5px 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 15;
}

.logo {
  display: table-cell;
  padding-left: 10%;
  position: relative;
  width: 50%;
}

.logo img {
  max-height: 100%;
  max-width: 100%;
  position: absolute;
  cursor: pointer;
}

.moto {
  font-family: "RobotoMedium" !important;
  display: block;
  float: right;
  line-height: 50px;
}

.motoContainer {
  display: table-cell;
  padding-right: 10%;
  vertical-align: middle;
  margin: 0px;
  width: 50%;
}

@media screen and (max-width: 600px) {
  .motoContainer {
    display: none;
  }
}
