@font-face {
  font-family: "Roboto";
  src: url("./fonts/Roboto.woff2") format("woff2"),
    url("./fonts/Roboto.woff") format("woff");
}
@font-face {
  font-family: "RobotoMedium";
  src: url("./fonts/RobotoMedium.woff2") format("woff2"),
    url("./fonts/RobotoMedium.woff") format("woff");
}
@font-face {
  font-family: "RobotoRegular";
  src: url("./fonts/RobotoRegular.woff2") format("woff2"),
    url("./fonts/RobotoRegular.woff") format("woff");
}

.about_page {
  position: absolute;
  min-height: 100%;
  width: 100%;
  margin: 0px;
  padding: 0px;
  z-index: 10;
  text-align: center;
  display: flex;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 0.1);
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
}

.about_content {
  flex: 1 0 auto;
}

.about_content p {
  text-align: justify;
  /* margin: 30px auto 0 auto; */
}

.a .logos {
  width: inherit;
}

@media (min-height: 800px) {
  .box {
    padding: 5% 0 0 0;
  }
}

#txt {
  font-family: "RobotoRegular";
  width: 55%;
  text-align: justify;
  margin: 30px auto 0 auto;
}

#txt_center {
  font-family: "RobotoRegular";
  width: 55%;
  text-align: center;
  margin: 30px auto 0 auto;
}

#txt_mobile {
  font-family: "RobotoRegular";
  width: 90%;
  text-align: center;
  margin: 30px auto 0 auto;
}

#title {
  font-family: "RobotoMedium" !important;
  text-align: center;
  margin: 20px auto 15px auto;
}

.logos {
  width: 55%;
  height: auto;
  text-align: center;
  margin: 0 auto;
}

.logos_mobile {
  width: 90%;
  height: auto;
  text-align: center;
  margin: 0 auto;
}

.closePageContainer {
  margin-bottom: 15px;
  width: 40px;
  position: fixed;
  top: 75px;
  height: auto;
  width: auto;
  right: 10px;
  z-index: 2;
}

.about_footer {
  width: 100%;
  bottom: 0px;
  flex-shrink: 0; /* Prevent Chrome, Opera, and Safari from letting these items shrink to smaller than their content's default minimum size. */
}

.my_profiles {
  margin: 0 10px;
}

#thesis_link {
  font-weight: bold;
  text-decoration: none;
  color: inherit;
}

#thesis_link:hover {
  border-bottom: 3px groove #e45340;
  border-radius: 5px;
}

/* LINKS */
#no_style {
  color: inherit; /* blue colors for links too */
  text-decoration: inherit; /* no underline */
  white-space: nowrap;
}
#no_style:hover {
  text-decoration: underline; /* no underline */
}

#tab {
  font-family: "RobotoRegular";
  width: 75%;
  text-align: justify;
  margin: 30px auto 0 auto;
}

#press_card {
  width: 75% !important;
  height: 20%;
  margin: auto;
}

#no_margin {
  margin: 0 !important;
}

.media_container {
  display: flex;
  flex-wrap: wrap;
  padding: 5px;
  justify-content: space-evenly;
}

.media_logo {
  height: 50px;
  width: auto;
  margin: 20px 10px;
}

#media_logo_makthes {
  height: 30px !important;
  margin: 40px 10px !important;
}

.mentions_list {
  display: flex;
  flex-direction: column;
}

.mention {
  /* background-color: #f1f1f1;
  width: 100px;
  margin: 10px;
  line-height: 75px;
  font-size: 30px; */
  margin: 30px 12%;
}
