@font-face {
  font-family: "Roboto";
  src: url("./fonts/Roboto.woff2") format("woff2"),
    url("./fonts/Roboto.woff") format("woff");
}
@font-face {
  font-family: "RobotoMedium";
  src: url("./fonts/RobotoMedium.woff2") format("woff2"),
    url("./fonts/RobotoMedium.woff") format("woff");
}
@font-face {
  font-family: "RobotoRegular";
  src: url("./fonts/RobotoRegular.woff2") format("woff2"),
    url("./fonts/RobotoRegular.woff") format("woff");
}

html,
body {
  margin: 0;
  height: 100%;
  /* overflow: hidden; */
}

.bg {
  background: url("/media/blurred_bg.png") no-repeat center fixed;
  height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.bg img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  max-width: 400px;
  width: 75vw;
}
