@font-face {
  font-family: "Roboto";
  src: url("./fonts/Roboto.woff2") format("woff2"),
    url("./fonts/Roboto.woff") format("woff");
}
@font-face {
  font-family: "RobotoMedium";
  src: url("./fonts/RobotoMedium.woff2") format("woff2"),
    url("./fonts/RobotoMedium.woff") format("woff");
}
@font-face {
  font-family: "RobotoRegular";
  src: url("./fonts/RobotoRegular.woff2") format("woff2"),
    url("./fonts/RobotoRegular.woff") format("woff");
}

html {
  overflow-y: overlay;
}
body {
  font-family: Roboto;
  margin: 0;
}

.sb {
  bottom: 0%;
  left: 50%;
  position: fixed;
  transform: translate(-50%, -50%);
  z-index: 2;
}

.sbroot {
  align-items: center;
  display: flex;
  padding: 2px 0;
  margin: 0px 10px;
  width: 75vw;
  max-width: 400px;
}

.sbroot > * {
  margin: 0px 10px;
}

@media screen and (max-width: 270px) {
  .sb {
    display: none;
  }
}

.sbinput {
  border: none;
  flex: 1;
  font-size: 16px;
  font-weight: 300;
  margin-left: 1px;
  outline: none;
  padding: 5px 20px;
}

.sbiconButton {
  padding: 10px;
}

.sbSearchiconButton:hover {
  color: #333333;
}

.sbGPSiconButton {
  color: #e45340;
}

.sbGPSiconButton:hover {
  color: #811411;
}

.sbiconButton:hover {
  background-color: transparent !important;
}

.sbdivider {
  height: 28px !important;
}

.buttonContainer {
  position: fixed;
  right: 10px;
  top: 75px;
  height: auto;
  width: auto;
  z-index: 2;
}

.buttonContainer > * {
  margin-bottom: 15px;
  width: 40px;
  background-color: rgb(255, 255, 255);
  border-radius: 2px;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px !important;
  cursor: pointer;
}

.infoContainer {
  height: 40px;
  width: 40px;
}

.sliderContainer {
  text-align: center;
  height: calc(25vh + 50px);
  max-height: 250px;
  padding: 4px 0px 10px 0px;
  width: 40px;
}

.sliderObject {
  height: 25vh;
  max-height: 200px;
}

.sliderContainer *:hover,
.sliderContainer *:focus,
.sliderContainer *:active {
  box-shadow: none !important;
}

.label {
  /* position: absolute; */
  /* width: 100%; */
  width: 40px;
  height: 40px;
  text-align: center;
  line-height: 40px !important;
}

/* VOLUME ON/OFF BUTTON */

.soundContainer {
  height: 40px;
  width: 40px;
}

.soundContainer:hover {
  background-color: rgb(255, 255, 255) !important;
}

.buttonContainer_iconContainer {
  background-color: transparent !important;
  background: none;
  border-radius: 0% !important;
  border: 0px;
  bottom: 0px;
  box-shadow: none;
  cursor: pointer;
  display: block;
  height: 40px;
  left: 0px;
  margin: 0px;
  overflow: hidden;
  padding: 0px;
  width: 40px;
}

.buttonContainer_icon {
  color: #666666 !important;
  height: 18px;
  padding: 8px;
  width: 18px;
  position: absolute;
}

.buttonContainer_icon:hover {
  color: #333333 !important;
}

#notificationOn {
  margin-top: -55px;
  z-index: 5 !important;
  position: fixed;
}

/* MAP */

.map {
  overflow: hidden;
  position: fixed;
}

@media screen and (max-height: 420px) {
  .sliderContainer {
    display: none;
  }
}

@media screen and (max-height: 495px) {
  .soundContainer {
    display: none;
  }
}

@media screen and (max-height: 235px) {
  .infoContainer {
    display: none;
  }
}
